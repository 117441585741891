import React, { useEffect, useMemo } from 'react';
import { Map } from 'mapbox-gl';
import { Feature } from 'geojson';

interface ProhibitedZoneMapProperties {
  coordinates: number[][];
  properties: {
    fillColor: string;
    strokeColor: string;
  };
}

interface ProhibitedZonesMapLayerProps {
  map: Map | undefined;
  prohibitedZones: ProhibitedZoneMapProperties[] | undefined;
}

export const ProhibitedZonesMapLayer: React.FC<ProhibitedZonesMapLayerProps> = ({
  map,
  prohibitedZones
}) => {
  const prohibitedZonesFeatures = useMemo(() => {
    if (!prohibitedZones?.length) return [];

    return prohibitedZones.map<Feature>(zone => ({
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: prohibitedZones.map(zone => zone.coordinates)
      },
      properties: zone.properties
    }));
  }, [prohibitedZones]);

  useEffect(() => {
    if (!map || !prohibitedZonesFeatures.length) return;
    const sourceId = 'prohibited-zones-source';
    const layerId = 'prohibited-zones-layer';

    map.addLayer(
      {
        id: 'prohibited-zones',
        type: 'fill',
        source: {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: prohibitedZonesFeatures
          }
        },
        paint: {
          'fill-color': ['get', 'fillColor'],
          'fill-opacity': 0.5
        }
      },
      'waterway-label'
    );

    map.addLayer(
      {
        id: 'prohibited-zones-outline',
        type: 'line',
        source: {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: prohibitedZonesFeatures
          }
        },
        paint: {
          'line-color': ['get', 'strokeColor'],
          'line-width': 1
        }
      },
      'waterway-label'
    );

    return () => {
      if (map.getLayer(layerId)) map.removeLayer(layerId);
      if (map.getSource(sourceId)) map.removeSource(sourceId);
    };
  }, [map]);

  return null;
};
