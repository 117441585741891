import { MutableRefObject } from 'react';
import mapboxgl from 'mapbox-gl';
import { Feature } from 'geojson';
import { completedTripSubscription_completedTrip } from '../../core-types';

export type ViewTypes = 'bikes' | 'parking' | 'live';
export const DEFAULT_VIEW = 'bikes';

export type MapTypes = 'static' | 'dynamic';

export const DEFAULT_FILTERS = {
  virtual: true,
  physical: true,
  hybrid: true,

  bike: true,
  ebike: true,
  ebike_with_childseat: true,
  scooter: true
};

export interface MapContextValue {
  completedTrip?: completedTripSubscription_completedTrip;
  disableMapControls?: boolean;
  activeDotId?: any;
  isMapLoaded: boolean;
  showLiveMap?: boolean | null;
  view: ViewTypes;
  filters?: typeof DEFAULT_FILTERS;
  switchFilterValue?: (key: keyof typeof DEFAULT_FILTERS) => void;
  handleBatteryChargeFilterChange?: (
    event: any,
    newValue: number | number[]
  ) => void;
  batteryChargeFilter?: number[];
  activeDotFeature?: Feature;
  mapContainerRef: MutableRefObject<HTMLDivElement | undefined>;
  mapRef: MutableRefObject<mapboxgl.Map | undefined>;
}

export interface LngLat {
  lng: number;
  lat: number;
}

export interface CoordinatesProperties {
  coordinates: [number, number];
  properties: any;
}
export interface ProhibitedZoneProperties {
  coordinates: number[][];
  properties: {
    fillColor: string;
    strokeColor: string;
  };
}
