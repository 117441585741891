import React, { FC } from 'react';
import { MapLayout } from './MapLayout';
import { MapContextProvider } from './MapContext';
import { ViewTypes, LngLat, DEFAULT_FILTERS } from './types';
import {
  Box,
  CoordinatesProperties,
  ProhibitedZoneProperties
} from '../../components';
import { completedTripSubscription_completedTrip } from '../../core-types';

export interface MapProps {
  showLiveMap?: boolean;
  enableAnimatedTrips?: boolean;
  completedTrip?: completedTripSubscription_completedTrip;
  mapHeight?: string | number;
  boundCoordinates?: LngLat[];
  dockGroupCoordinates?: CoordinatesProperties[];
  freeFloatingCoordinates?: CoordinatesProperties[];
  freeFloatingEnabled?: boolean | undefined | null;
  prohibitedZones?: ProhibitedZoneProperties[] | undefined;
  disableMapControls?: boolean;
  inSeason?: boolean;
  filters?: typeof DEFAULT_FILTERS;
  batteryChargeFilter?: number[];
  switchFilterValue?: (key: keyof typeof DEFAULT_FILTERS) => void;
  handleBatteryChargeFilterChange?: (
    event: any,
    newValue: number | number[]
  ) => void;
  view?: ViewTypes;
}

export const Map: FC<MapProps> = props => {
  const {
    showLiveMap,
    completedTrip,
    mapHeight = '80vh',
    enableAnimatedTrips = false,
    boundCoordinates,
    dockGroupCoordinates,
    freeFloatingCoordinates,
    freeFloatingEnabled,
    disableMapControls,
    prohibitedZones,
    inSeason,
    view = 'bikes',
    filters,
    batteryChargeFilter,
    switchFilterValue,
    handleBatteryChargeFilterChange
  } = props;

  return (
    <MapContextProvider
      boundCoordinates={boundCoordinates}
      disableMapControls={disableMapControls}
      dockGroupCoordinates={dockGroupCoordinates}
      freeFloatingCoordinates={freeFloatingCoordinates}
      freeFloatingEnabled={freeFloatingEnabled}
      showLiveMap={showLiveMap}
      view={view}
      filters={filters}
      batteryChargeFilter={batteryChargeFilter}
      handleBatteryChargeFilterChange={handleBatteryChargeFilterChange}
      completedTrip={completedTrip}
      switchFilterValue={switchFilterValue}
      prohibitedZones={prohibitedZones}
    >
      <Box minHeight={mapHeight}>
        <MapLayout
          enableAnimatedTrips={enableAnimatedTrips}
          inSeason={inSeason}
        />
      </Box>
    </MapContextProvider>
  );
};
