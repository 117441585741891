import React, {
  FC,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import mapboxgl from 'mapbox-gl';
import { Feature } from 'geojson';
import useBool from '../../lib/hooks/useBool';
import {
  LngLat,
  MapContextValue,
  ViewTypes,
  DEFAULT_VIEW,
  CoordinatesProperties,
  DEFAULT_FILTERS,
  ProhibitedZoneProperties
} from './types';
import { useStyledTheme } from '../../lib/hooks';
import { completedTripSubscription_completedTrip } from '../../core-types';
import CustomMarkers from './CustomMarkers';
import { useSystemConfig } from '../../lib/system-config-context';
import {
  getDockDots,
  getDockDotsText,
  DOTS_TEXT_ID,
  DOCK_DOTS_LAYER_ID,
  DOCK_GROUP_DOTS_SOURCE_ID
} from './Markers/Docks';
import {
  getBikeDotsStyle,
  bikeNames,
  BIKE_DOTS_SOURCE_ID,
  BIKE_NAMES_LAYER_ID,
  BIKE_DOTS_LAYER_ID,
  handleClickEvent
} from './Markers/Bikes';
import {
  getEbikeDotsStyle,
  ebikeNames,
  EBIKE_DOTS_LAYER_ID,
  EBIKE_NAMES_LAYER_ID,
  EBIKE_DOTS_SOURCE_ID
} from './Markers/Ebikes';
import { MAPBOX_ACCESS_TOKEN } from '../../lib/constants';
import { ProhibitedZonesMapLayer } from '../../modules/ProhibitedZones';

export const MapContext = React.createContext<MapContextValue>({
  isMapLoaded: false,
  view: DEFAULT_VIEW,
  filters: DEFAULT_FILTERS,
  batteryChargeFilter: [0, 100],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  switchFilterValue: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleBatteryChargeFilterChange: () => {},
  mapContainerRef: { current: undefined },
  mapRef: { current: undefined }
});

export interface MapContextProviderProps {
  disableMapControls?: boolean;
  showLiveMap?: boolean;
  view: ViewTypes;
  filters?: typeof DEFAULT_FILTERS;
  batteryChargeFilter?: number[];
  switchFilterValue?: (key: keyof typeof DEFAULT_FILTERS) => void;
  handleBatteryChargeFilterChange?: (
    event: any,
    newValue: number | number[]
  ) => void;
  boundCoordinates?: LngLat[];
  dockGroupCoordinates?: CoordinatesProperties[];
  freeFloatingEnabled?: boolean | undefined | null;
  freeFloatingCoordinates?: CoordinatesProperties[];
  prohibitedZones?: ProhibitedZoneProperties[] | undefined;
  completedTrip?: completedTripSubscription_completedTrip;
}

export const MapContextProvider: FC<MapContextProviderProps> = props => {
  const {
    disableMapControls,
    showLiveMap,
    boundCoordinates,
    view,
    children,
    dockGroupCoordinates,
    freeFloatingCoordinates,
    freeFloatingEnabled,
    prohibitedZones,
    filters,
    batteryChargeFilter,
    handleBatteryChargeFilterChange,
    switchFilterValue,
    completedTrip
  } = props;

  const theme = useStyledTheme();
  const systemConfig = useSystemConfig();

  const [isMapLoaded, , setMapLoaded] = useBool();
  const [activeDotId, setActiveDotId] = useState(null);

  const mapRef = useRef<mapboxgl.Map>();
  const mapContainerRef = useRef<HTMLDivElement>();

  const bounds = useMemo(() => {
    if (!boundCoordinates?.length) return null;

    const isNotEnoughBoundCoordinates = boundCoordinates.length < 2;
    if (isNotEnoughBoundCoordinates) return null;

    return boundCoordinates.reduce((newBounds, bound) => {
      newBounds.extend(new mapboxgl.LngLat(bound.lng, bound.lat));
      return newBounds;
    }, new mapboxgl.LngLatBounds(boundCoordinates[0], boundCoordinates[0]));
  }, [boundCoordinates]);

  const applyFilter = (dataSet, maxBatteryCharge, minBatteryCharge) => {
    if (!dataSet?.length) return [];
    const filtered = dataSet.filter(
      bike =>
        bike &&
        bike.properties &&
        bike.properties.batteryCharge <= maxBatteryCharge &&
        bike.properties.batteryCharge >= minBatteryCharge
    );
    return filtered;
  };

  const dockGroupFeatures = useMemo(() => {
    if (!dockGroupCoordinates?.length) return [];

    return dockGroupCoordinates.map<Feature>(dot => ({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: dot.coordinates
      },
      properties: dot.properties
    }));
  }, [dockGroupCoordinates]);

  const freeFloatingBikes = freeFloatingCoordinates?.filter(
    bike => bike.properties.category === 'bike'
  );

  const freeFloatingEbikes = freeFloatingCoordinates?.filter(
    bike => bike.properties.category === 'ebike'
  );

  const freeFloatingEbikesFilterd = useMemo(() => {
    if (!freeFloatingEbikes?.length) return [];
    const maxBatteryCharge = batteryChargeFilter && batteryChargeFilter[1];
    const minBatteryCharge = batteryChargeFilter && batteryChargeFilter[0];
    return applyFilter(freeFloatingEbikes, maxBatteryCharge, minBatteryCharge);
  }, [batteryChargeFilter]);

  const freeFloatingBikeDots = useMemo(() => {
    if (!freeFloatingBikes?.length) return [];

    return freeFloatingBikes.map<Feature>(dot => ({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: dot.coordinates
      },
      properties: dot.properties
    }));
  }, [freeFloatingBikes]);

  const freeFloatingEbikeDots = useMemo(() => {
    if (!freeFloatingEbikesFilterd?.length) return [];

    return freeFloatingEbikesFilterd.map(dot => ({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: dot.coordinates
      },
      properties: dot.properties
    }));
  }, [freeFloatingEbikesFilterd]);

  const activeDotFeature = useMemo(() => {
    if (!activeDotId) return;

    return (
      dockGroupFeatures.find(df => df?.properties?.id === activeDotId) ||
      freeFloatingBikeDots.find(df => df?.properties?.id === activeDotId) ||
      freeFloatingEbikeDots.find(df => df?.properties?.id === activeDotId)
    );
  }, [
    activeDotId,
    dockGroupFeatures,
    freeFloatingBikeDots,
    freeFloatingEbikeDots
  ]);

  const primaryProp = useMemo(() => {
    return view === 'bikes' || view === 'live'
      ? 'availableVehicles'
      : 'availableDocks';
  }, [view]);

  const dotCircleColor = useMemo<mapboxgl.Expression>(() => {
    return [
      'match',
      ['get', primaryProp],
      0,
      theme.colors.neutral[4],
      /* other */ theme.colors.primaryText
    ];
  }, [primaryProp, theme.colors]);

  const paintTextColor = useMemo<mapboxgl.Expression>(() => {
    return [
      'match',
      ['get', primaryProp],
      0,
      theme.colors.text,
      /* other */ '#ffffff'
    ];
  }, [primaryProp, theme.colors]);

  useEffect(() => {
    if (!isMapLoaded || !mapRef.current || !mapRef.current.loaded()) {
      return;
    }

    const map = mapRef.current;
    if (!map) {
      return;
    }

    map.setPaintProperty(DOCK_DOTS_LAYER_ID, 'circle-color', [
      'case',
      ['==', ['get', 'enabled'], true],
      dotCircleColor,
      theme.colors.neutral[4]
    ]);

    map.setLayoutProperty(DOTS_TEXT_ID, 'text-field', [
      'case',
      ['==', ['get', 'enabled'], true],
      ['get', primaryProp],
      '-'
    ]);

    map.setPaintProperty(DOTS_TEXT_ID, 'text-color', paintTextColor);
  }, [primaryProp, dotCircleColor, isMapLoaded, paintTextColor]);

  useEffect(() => {
    if (!mapRef?.current) return;

    const source = mapRef.current.getSource(DOCK_GROUP_DOTS_SOURCE_ID);
    if (!source) return;

    // @ts-ignore
    source.setData({
      type: 'FeatureCollection',
      features: dockGroupFeatures
    });
  }, [dockGroupFeatures]);

  useLayoutEffect(() => {
    if (!bounds) {
      return;
    }

    mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN;
    const map = new mapboxgl.Map({
      style: 'mapbox://styles/mapbox/streets-v9',
      container: mapContainerRef.current as HTMLElement,
      bounds
      // maxBounds doesn't really work without a padding option https://github.com/mapbox/mapbox-gl-js/issues/4268
      // maxBounds: systemBounds
    });
    const nav = new mapboxgl.NavigationControl({ showCompass: false });
    map.addControl(nav, 'bottom-left');
    map.on('load', () => {
      setActiveDotId(null);
      mapRef.current = map;
      map.fitBounds(bounds, { padding: 100 });
      setMapLoaded();

      CustomMarkers.map(marker => {
        const customMarker = new Image();
        customMarker.onload = () => map.addImage(marker.name, customMarker);
        customMarker.src = marker.src;
      });

      if (dockGroupFeatures.length) {
        const dockGroupSource: mapboxgl.GeoJSONSourceRaw = {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: dockGroupFeatures
          }
        };

        map.addSource(DOCK_GROUP_DOTS_SOURCE_ID, dockGroupSource);

        map.addLayer(getDockDots(theme, dotCircleColor));

        map.addLayer(getDockDotsText(primaryProp, paintTextColor));

        handleClickEvent(map, setActiveDotId, DOCK_DOTS_LAYER_ID);
      }

      //This code is pretty awful and should be refactored
      // If free-floating enabled
      if (freeFloatingEnabled) {
        const bikeSource: mapboxgl.GeoJSONSourceRaw = {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: freeFloatingBikeDots
          }
        };

        map.addSource(BIKE_DOTS_SOURCE_ID, bikeSource);

        map.addLayer(getBikeDotsStyle(systemConfig));

        map.addLayer(bikeNames);

        handleClickEvent(map, setActiveDotId, BIKE_DOTS_LAYER_ID);

        const ebikeSource: mapboxgl.GeoJSONSourceRaw = {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: freeFloatingEbikeDots
          }
        };

        map.addSource(EBIKE_DOTS_SOURCE_ID, ebikeSource);

        map.addLayer(getEbikeDotsStyle(systemConfig));

        map.addLayer(ebikeNames);

        handleClickEvent(map, setActiveDotId, EBIKE_DOTS_LAYER_ID);
      }
    });

    map.on('idle', () => {
      if (!filters?.bike) {
        if (
          map.getLayer(BIKE_DOTS_LAYER_ID) &&
          map.getLayer(BIKE_NAMES_LAYER_ID)
        ) {
          map.setLayoutProperty(BIKE_DOTS_LAYER_ID, 'visibility', 'none');
          map.setLayoutProperty(BIKE_NAMES_LAYER_ID, 'visibility', 'none');
        }
      }
      if (!filters?.ebike) {
        if (
          map.getLayer(EBIKE_DOTS_LAYER_ID) &&
          map.getLayer(EBIKE_NAMES_LAYER_ID)
        ) {
          map.setLayoutProperty(EBIKE_DOTS_LAYER_ID, 'visibility', 'none');
          map.setLayoutProperty(EBIKE_NAMES_LAYER_ID, 'visibility', 'none');
        }
      }
    });
  }, [bounds, filters?.bike, filters?.ebike, batteryChargeFilter]);

  return (
    <MapContext.Provider
      value={{
        disableMapControls,
        showLiveMap,
        mapRef,
        mapContainerRef,
        activeDotFeature,
        view,
        filters,
        batteryChargeFilter,
        switchFilterValue,
        handleBatteryChargeFilterChange,
        activeDotId,
        isMapLoaded,
        completedTrip
      }}
    >
      <ProhibitedZonesMapLayer
        map={mapRef.current}
        prohibitedZones={prohibitedZones}
      />
      {children}
    </MapContext.Provider>
  );
};

export const useMapContext = () => useContext(MapContext);
